<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="24">
        <a-table
          :loading="loading"
          v-if="cotisations.length"
          bordered
          :dataSource="cotisations"
          :columns="columns"
          :rowKey="id"
          :pagination="false"
        >
          <template slot="reference" slot-scope="text, record">
            {{ get(record, "code") }}
          </template>
          <template slot="etat" slot-scope="text, record">
            <a v-if="record.isTraie == 0" style="color:orange">En cours</a>
            <a v-else>Réglé</a>
          </template>
          <template slot="montant" slot-scope="text, record">
            {{ record.montant | currency }}
          </template>
          <template slot="date" slot-scope="text, record">
            {{ record.dt_encaissement | date }}
          </template>

          <template slot="mode_paiement" slot-scope="text, record"
            >{{ displayPaiementMode(record) }}
          </template>
          <template slot="type" slot-scope="text, record"
            >{{ displayPaiementType(record) }}
          </template>
          <template slot="ecriture" slot-scope="text, record"
            >{{ record.ecriture }} {{ record.reglement.refChkVir }}
          </template>
          <template slot="dt_demande" slot-scope="text, record"
            >{{ record.dtCreate | date }}
          </template>
          <template slot="valide" slot-scope="text, record">
            <a-checkbox
              :checked="isReglementValide(record)"
              @change="onValideChange($event, record)"
              v-if="isAdmin"
            >
              <a-icon
                type="check-circle"
                v-if="isReglementValide(record)"
                theme="twoTone"
                class="action_icons"
                twoToneColor="#52c41a"
              />
              <a-icon
                v-else
                type="close-circle"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
            </a-checkbox>
            <span v-else>
              <a-icon
                type="check-circle"
                v-if="isReglementValide(record)"
                theme="twoTone"
                class="action_icons"
                twoToneColor="#52c41a"
              />
              <a-icon
                v-else
                type="close-circle"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
            </span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <!-- _________________________VISUALIZE -->
            <a-tooltip placement="top" v-if="isFileUploaded(record)">
              <template slot="title">
                <span>Visualiser</span>
              </template>
              <a-icon
                type="search"
                @click="previewDocument(record.file_link)"
                class="action_icons"
              />
            </a-tooltip>
            <!-- ___________________DELETE -->
            <a-popconfirm
              title="Etes-vous sûr de vouloir supprimer cette ligne ?"
              @confirm="() => handleDelete(record.id)"
              v-if="isUpdatable(record)"
            >
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Suppression</span>
                </template>
                <a-icon
                  type="delete"
                  class="action_icons"
                  theme="twoTone"
                  twoToneColor="#eb2f96"
                />
              </a-tooltip>
            </a-popconfirm>
            <!-- _______________________EDIT -->
            <a-tooltip placement="top" v-if="isUpdatable(record)">
              <template slot="title">
                <span>Edition</span>
              </template>
              <a-icon
                type="edit"
                v-on:click="handleEdit(record.id)"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#52c41a"
                style="display: inline-block"
              />
            </a-tooltip>
          </template>
          <template slot="upload" slot-scope="text, record">
            <!-- _______________________UPLOAD -->
            <a-upload
              name="file"
              :action="uploadUrl(record.id)"
              :headers="{ Authorization: `Bearer ${getAuthorizationToken()}` }"
              v-if="isUpdatable(record)"
              @change="handleUploadChange($event, record.id)"
            >
              <a-button class="ant-btn-primary">
                <a-icon type="upload" />
                Joindre
              </a-button>
            </a-upload>
          </template>
        </a-table>
        <EmptyResult v-else />
        <a-pagination
          v-if="cotisations.length"
          style="margin-top:1%"
          @change="onPaginationChange"
          @showSizeChange="onPageSizeChange"
          :current="pagination.current"
          :total="pagination.total"
          :pageSize="pagination.pageSize"
          :showSizeChanger="true"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import EmptyResult from "@/components/common/EmptyResult";

const columns = [
  {
    title: "Référence",
    scopedSlots: { customRender: "reference" },
  },
  {
    title: "Type",
    scopedSlots: { customRender: "mode_paiement" },
  },
  {
    title: "Mode paiement",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "Montant",
    scopedSlots: { customRender: "montant" },
  },
  {
    title: "Valide",
    scopedSlots: { customRender: "valide" },
  },
  {
    title: "Opération",
    scopedSlots: { customRender: "operation" },
  },
  {
    title: "Piéce jointe",
    scopedSlots: { customRender: "upload" },
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 20,
    },
  },
};
const ESPECE = "ESP";
const VERSEMENT = "VRM";
const CHEQUE = "CHK";
export default {
  name: "CotisationsList",
  components: {
    EmptyResult,
  },
  data() {
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout,
      columns: columns,
      paie: false,
      processing: false,
      selected_id: null,
      confirmLoading: false,
      ESPECE,
      CHEQUE,
      VERSEMENT,
    };
  },
  props: {
    loading: { type: Boolean, default: () => false },
    cotisations: { type: Array },
    pagination: { type: Object },
    isAdmin: { type: Boolean, default: () => false },
  },
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
    }),
  },
  methods: {
    isUpdatable(reglement) {
      return (
        this.isAdmin || (this.isReglementNotValide(reglement) && !this.isAdmin)
      );
    },
    isReglementValide(reglement) {
      return !!reglement.is_valide;
    },
    isReglementNotValide(reglement) {
      return !this.isReglementValide(reglement);
    },
    onValideChange(e, paiement) {
      this.$emit("validate_paiement", paiement);
    },
    handleUploadChange(info, id) {
      if (info.file.status === "done") {
        this.$_showSuccessMessage(
          `${info.file.name} a été importé avec succès`
        );
        this.$emit("upload_success");
      }
      if (info.file.status === "removed") {
        this.$emit("delete_file", id);
        this.$_showSuccessMessage(
          `${info.file.name} a été supprimé avec succès`
        );
      }
    },
    uploadUrl(reglement_id) {
      return `${this.base_url}/upload-paiement-documents/${reglement_id}`;
    },
    isFileUploaded(reglement) {
      return !!reglement.file_name;
    },
    displayPaiementType(reglement) {
      if (this.get(reglement, "mode_paiement.code")) {
        return reglement.mode_paiement.code + "-" + reglement.num_cheque_recu;
      }
      return (
        "Cotisation " + this.date(this.get(reglement, "exercice.annee"), "YYYY")
      );
    },
    displayPaiementMode(reglement) {
      return this.get(reglement, "type_reglement.nom_fr");
    },
    handleDelete(id) {
      this.$emit("delete", id);
    },
    handleEdit(id) {
      this.$emit("edit", id);
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    onPaginationChange(pagination) {
      this.$emit("set_pagination", { ...this.pagination, current: pagination });
      this.$emit("refresh_data");
    },
    onPageSizeChange(current, size) {
      this.$emit("set_pagination", { ...this.pagination, pageSize: size });
      this.$emit("refresh_data");
    },
  },
};
</script>
<style scoped>
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.action_icons {
  margin: 4px;
  margin-left: 10px;
  font-size: large;
}
</style>
