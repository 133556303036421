var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: 22 } },
                [
                  _vm.dataSource.length
                    ? _c("a-table", {
                        attrs: {
                          bordered: "",
                          dataSource: _vm.dataSource,
                          columns: _vm.columns,
                          pagination: false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row_index",
                              fn: function(text, record, index) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(index + 1) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "exercice",
                              fn: function(text, record, index) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("year")(
                                          _vm.get(record, "exercice.annee")
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "user",
                              fn: function(text, record, index) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(record.user.nom_fr) +
                                      " " +
                                      _vm._s(record.user.prenom_fr) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "montant",
                              fn: function(text, record, index) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(record.montant)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "statut_prof",
                              fn: function(text, record) {
                                return [
                                  _c("StatuProfessionalIndicator", {
                                    attrs: {
                                      "statut-professionnel":
                                        record.etat_activite
                                          .statut_professionnel
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "type_reglement",
                              fn: function(text, record, index) {
                                return [
                                  _vm._v(_vm._s(record.type_reglement.nom_fr))
                                ]
                              }
                            },
                            {
                              key: "footer",
                              fn: function(currentPageData) {
                                return [
                                  _c("div", { staticClass: "text-al" }, [
                                    _c("span", { staticClass: "total" }, [
                                      _vm._v("Total")
                                    ]),
                                    _c("span", { staticClass: "total_price" }, [
                                      _vm._v(
                                        _vm._s(_vm._f("currency")(_vm.somme))
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4256168674
                        )
                      })
                    : _c("EmptyResult"),
                  _vm.dataSource.length
                    ? _c("a-pagination", {
                        staticStyle: { "margin-top": "1%" },
                        attrs: {
                          current: _vm.pagination.current,
                          total: _vm.pagination.total,
                          pageSize: _vm.pagination.pageSize,
                          showSizeChanger: true
                        },
                        on: {
                          change: _vm.onPaginationChange,
                          showSizeChange: _vm.onPageSizeChange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }