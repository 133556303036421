<template>
  <div>
    <a-spin :spinning="loading">
      <a-row type="flex" justify="center">
        <a-col :span="22">
          <a-table
            bordered
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :pagination="false"
          >
            <template slot="row_index" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <template slot="exercice" slot-scope="text, record, index">
              {{ get(record, "exercice.annee") | year }}
            </template>
            <template slot="user" slot-scope="text, record, index">
              {{ record.user.nom_fr }} {{ record.user.prenom_fr }}
            </template>
            <template slot="montant" slot-scope="text, record, index">
              {{ record.montant | currency }}
            </template>
            <template slot="statut_prof" slot-scope="text, record">
              <StatuProfessionalIndicator :statut-professionnel="record.etat_activite.statut_professionnel"/>
          </template>
            <template slot="type_reglement" slot-scope="text, record, index">{{
              record.type_reglement.nom_fr
            }}</template>
            <template slot="footer" slot-scope="currentPageData">
              <div class="text-al">
                <span class="total">Total</span>
                <span class="total_price">{{ somme | currency }}</span>
              </div>
            </template>
          </a-table>
          <EmptyResult v-else />
          <a-pagination
            v-if="dataSource.length"
            style="margin-top:1%"
            @change="onPaginationChange"
            @showSizeChange="onPageSizeChange"
            :current="pagination.current"
            :total="pagination.total"
            :pageSize="pagination.pageSize"
            :showSizeChanger="true"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
  import EmptyResult from "@/components/common/EmptyResult";
  import StatuProfessionalIndicator from "@/components/common/StatuProfessionalIndicator";

  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"},
    },
    {
      title: "Excercice",
      scopedSlots: {customRender: "exercice"},
    },
    {
      title: "Etat",
    scopedSlots: { customRender: "statut_prof" },
  },
  {
    title: "Montant",
    scopedSlots: { customRender: "montant" },
  },
  {
    title: "Type",
    scopedSlots: { customRender: "type_reglement" },
  },
];
export default {
  name: "ReglementsList",
  components: {
    EmptyResult,
    StatuProfessionalIndicator
  },
  data() {
    return {
      columns: columns,
    };
  },
  props: [
    "dataSource",
    "loading",
    "modesReglement",
    "pagination",
    "isAdmin",
    "somme",
  ],
  methods: {
    onPaginationChange(pagination) {
      this.$emit("set_pagination", { ...this.pagination, current: pagination });
      this.$emit("refresh_data");
    },
    onPageSizeChange(current, size) {
      this.$emit("set_pagination", { ...this.pagination, pageSize: size });
      this.$emit("refresh_data");
    },
  },
};
</script>
<style scoped>
.total {
  font-size: 2rem;
  margin-right: 1%;
}
.total_price {
  font-size: 2.1rem;
  color: #e23434;
}
.text-al {
  text-align: right;
}
</style>
