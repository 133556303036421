<template>
  <a-modal
    title="L'adhérent est à jour jusqu'a la date suivante"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="add_apssif_paiement"
      name="add_apssif_paiement"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-form-item v-bind="formItemLayout" label="Exercice">
          <a-select
            :filter-option="filterExercice"
            option-filter-prop="children"
            show-search
            v-decorator="[
              'exercice',
              {
                rules: [
                  { required: true, message: 'Exercice est obligatoire!' },
                  { validator: checkEmptyValue },
                ],
              },
            ]"
            placeholder="Choisir un exercice"
          >
            <a-select-option :key="0">Choisir un exercice</a-select-option>
            <a-select-option v-for="exercice in exercices" :key="exercice.annee"
              >{{ exercice.annee | year }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Mode paiement">
          <a-select
            v-decorator="['mode_paiement_id']"
            placeholder="Sélectionner un mode de paiement"
          >
            <a-select-option v-for="mode in modes_reglement" :key="mode.id"
              >{{ mode.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="N° du Reçu/Virement">
          <a-input  placeholder="N° du Reçu/Virement" v-decorator="['num_cheque_recu']" />
        </a-form-item>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "PassifPaiementEditor",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
    };
  },
  props: ["visible", "exercices", "processing"],
  computed: {
    ...mapState({
      modes_reglement: (state) => state.mode_reglement.modes_reglement,
    }),
  },
  methods: {
    filterExercice(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    checkEmptyValue(rule, value, callback) {
      if (parseInt(value) === 0) {
        callback("Il faut choisir une option");
        return;
      }
      callback();
    },
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
  },
};
</script>
