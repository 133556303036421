var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("b", { style: { color: _vm.getPaiementColor() } }, [
      _vm._v(
        "\n    " + _vm._s(_vm._f("currency")(_vm.absoluteBalance)) + "\n  "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }