<template>
  <span>
    <b v-bind:style="{ color: getPaiementColor() }">
      {{ absoluteBalance | currency }}
    </b>
  </span>
</template>
<script>
export default {
  name: "SoldeIndicator",
  props: { balance: { type: Number, default: () => 0 } },
  computed: {
    absoluteBalance() {
      return Math.abs(Number(this.balance));
    },
  },
  methods: {
    getPaiementColor() {
      return this.isPayeAjour(Number(this.balance)) ? this.GREEN_COLOR : this.RED_COLOR;
    },
  },
};
</script>
