var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _vm.cotisations.length
                ? _c("a-table", {
                    attrs: {
                      loading: _vm.loading,
                      bordered: "",
                      dataSource: _vm.cotisations,
                      columns: _vm.columns,
                      rowKey: _vm.id,
                      pagination: false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "reference",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.get(record, "code")) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "etat",
                          fn: function(text, record) {
                            return [
                              record.isTraie == 0
                                ? _c(
                                    "a",
                                    { staticStyle: { color: "orange" } },
                                    [_vm._v("En cours")]
                                  )
                                : _c("a", [_vm._v("Réglé")])
                            ]
                          }
                        },
                        {
                          key: "montant",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("currency")(record.montant)) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "date",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("date")(record.dt_encaissement)
                                  ) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "mode_paiement",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                _vm._s(_vm.displayPaiementMode(record)) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "type",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                _vm._s(_vm.displayPaiementType(record)) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "ecriture",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                _vm._s(record.ecriture) +
                                  " " +
                                  _vm._s(record.reglement.refChkVir) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "dt_demande",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("date")(record.dtCreate)) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "valide",
                          fn: function(text, record) {
                            return [
                              _vm.isAdmin
                                ? _c(
                                    "a-checkbox",
                                    {
                                      attrs: {
                                        checked: _vm.isReglementValide(record)
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onValideChange(
                                            $event,
                                            record
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.isReglementValide(record)
                                        ? _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "check-circle",
                                              theme: "twoTone",
                                              twoToneColor: "#52c41a"
                                            }
                                          })
                                        : _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "close-circle",
                                              theme: "twoTone",
                                              twoToneColor: "#eb2f96"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _vm.isReglementValide(record)
                                        ? _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "check-circle",
                                              theme: "twoTone",
                                              twoToneColor: "#52c41a"
                                            }
                                          })
                                        : _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "close-circle",
                                              theme: "twoTone",
                                              twoToneColor: "#eb2f96"
                                            }
                                          })
                                    ],
                                    1
                                  )
                            ]
                          }
                        },
                        {
                          key: "operation",
                          fn: function(text, record) {
                            return [
                              _vm.isFileUploaded(record)
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Visualiser")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: { type: "search" },
                                        on: {
                                          click: function($event) {
                                            return _vm.previewDocument(
                                              record.file_link
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.isUpdatable(record)
                                ? _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title:
                                          "Etes-vous sûr de vouloir supprimer cette ligne ?"
                                      },
                                      on: {
                                        confirm: function() {
                                          return _vm.handleDelete(record.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Suppression")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "delete",
                                              theme: "twoTone",
                                              twoToneColor: "#eb2f96"
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isUpdatable(record)
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Edition")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        staticStyle: {
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          type: "edit",
                                          theme: "twoTone",
                                          twoToneColor: "#52c41a"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEdit(record.id)
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "upload",
                          fn: function(text, record) {
                            return [
                              _vm.isUpdatable(record)
                                ? _c(
                                    "a-upload",
                                    {
                                      attrs: {
                                        name: "file",
                                        action: _vm.uploadUrl(record.id),
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            _vm.getAuthorizationToken()
                                        }
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUploadChange(
                                            $event,
                                            record.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        { staticClass: "ant-btn-primary" },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "upload" }
                                          }),
                                          _vm._v(
                                            "\n              Joindre\n            "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      757298972
                    )
                  })
                : _c("EmptyResult"),
              _vm.cotisations.length
                ? _c("a-pagination", {
                    staticStyle: { "margin-top": "1%" },
                    attrs: {
                      current: _vm.pagination.current,
                      total: _vm.pagination.total,
                      pageSize: _vm.pagination.pageSize,
                      showSizeChanger: true
                    },
                    on: {
                      change: _vm.onPaginationChange,
                      showSizeChange: _vm.onPageSizeChange
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }